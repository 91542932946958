export const ORDERS_OBJ = {
  id: null,
  menu_items: [],
  quantity: {},
  notes: "",
  ordered_at: null,
  prepared: false,
  prepared_at: null,
  delivered: false,
  delivered_at: null,
  complete: false,
}

export const DEFAULT_ORDER = {
  menu_items: [],
  quantity: {},
  notes: "",
}